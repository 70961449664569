<template>
  <div>
    <BaseHeader
      :title="$t('partner.title')"
      :style-variant="13"
    >
      <template v-slot:design>
        <HeaderDesign />
      </template>

      <template v-slot:desc>
        <p>
          {{ $t('partner.subtitle') }}
        </p>
      </template>
      <template
        v-if="filters"
        v-slot:content
      >
        <v-row
          class="pa-4"
        >
          <v-col
            cols="12"
            lg="9"
          >
            <p>
              {{ $t('partner.body-texts.0') }}
            </p>
            <v-chip
              v-for="(filter,index) of filtersArray"
              :key="index"
              :outlined="!filters[filter].isActive"
              class="ma-2"
              color="primary"
              @click="() => toggleFilter(filter)"
            >
              <span>{{ $t(ReadablePartnerTags[filter]) }} ({{ filters[filter].count }})</span>
            </v-chip>
          </v-col>
        </v-row>
      </template>
    </BaseHeader>
    <v-row v-if="offers.length > 0">
      <v-col
        v-for="voucher in offers"
        :key="voucher.type"
        cols="12"
        md="6"
        xl="4"
      >
        <voucher
          :voucher="voucher"
        />
      </v-col>
    </v-row>
    <v-card
      v-else
      outlined
    >
      <no-content-screen
        :title="$t('partner.no-content-screen.title')"
        :sub-title="$t('partner.no-content-screen.subtitle')"
      />
    </v-card>
  </div>
</template>

<script>
import brandingMixin from '@/mixins/branding'
import Voucher from './Voucher'
import COMPANY from './Company.gql'
import BaseHeader from '@/components/BaseHeader.vue'
import { PARTNER_TYPES, STATIC_PARTNERS, PARTNER_ORDER, PARTNER_TAGS, ReadablePartnerTags } from './helper/partner'
import NoContentScreen from '@/components/NoContentScreen.vue'
import HeaderDesign from './HeaderDesign.vue'

export default {
  components: {
    Voucher,
    BaseHeader,
    NoContentScreen,
    HeaderDesign
  },
  mixins: [brandingMixin],
  data () {
    return {
      filters: {},
      ReadablePartnerTags
    }
  },
  apollo: {
    company: {
      query: COMPANY,
      variables () {
        return {
          id: this.$auth.user.companyId
        }
      }
    }
  },
  computed: {
    offers () {
      const { locale } = this.$i18n
      return this.partnerForPlatform()
        .sort((a, b) => PARTNER_ORDER.indexOf(a.type) - PARTNER_ORDER.indexOf(b.type))
        .filter(partner => this.filters[PARTNER_TAGS.ALL].isActive || PARTNER_TYPES[this.platform][partner.type][locale]?.tags.some(tag => this.filters[tag].isActive))
    },
    filtersArray () {
      return Object.keys(this.filters)
    }
  },
  watch: {
    company () {
      if (this.company) {
        const { locale } = this.$i18n
        const availablePartners = this.partnerForPlatform().reduce((arr, item) => arr.concat(item.type), [])
        const allPartnersCount = Object.keys(PARTNER_TYPES[this.platform]).reduce((count, partner) => count + (availablePartners.includes(partner) ? 1 : 0), 0)
        const tags = {
          [PARTNER_TAGS.ALL]: {
            isActive: true,
            count: allPartnersCount
          }
        }
        Object.keys(PARTNER_TYPES[this.platform]).forEach(partner => {
          if (availablePartners.includes(partner)) {
            PARTNER_TYPES[this.platform][partner][locale].tags.forEach(tag => {
              if (!tags[tag]) tags[tag] = { isActive: false, count: 1 }
              else tags[tag].count++
            })
          }
        })
        this.filters = Object.assign({}, this.filters, tags)
      }
    }
  },
  methods: {
    toggleFilter (tag) {
      if (tag === PARTNER_TAGS.ALL) {
        Object.keys(this.filters).forEach(key => {
          this.$set(this.filters[key], 'isActive', false)
        })
        this.$set(this.filters[PARTNER_TAGS.ALL], 'isActive', true)
      } else {
        this.$set(this.filters[PARTNER_TAGS.ALL], 'isActive', false)
        this.$set(this.filters[tag], 'isActive', !this.filters[tag].isActive)
        this.handleNoFilter()
      }
    },
    handleNoFilter () {
      if (Object.values(this.filters).every((value) => value.isActive === false)) {
        this.$set(this.filters[PARTNER_TAGS.ALL], 'isActive', true)
      }
    },
    partnerForPlatform () {
      return [...(this.company?.vouchers || []), ...STATIC_PARTNERS]
        .filter(partner => Boolean(PARTNER_TYPES[this.platform][partner.type]))
    }
  }
}
</script>
