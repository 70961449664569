<template>
  <v-card
    v-if="voucherContent"
    outlined
    height="100%"
  >
    <v-card-title class="my-4">
      <div
        v-if="voucherContent.title"
        class="title"
      >
        <v-img
          contain
          max-height="60px"
          max-width="60px"
          :src="voucherContent.logo"
        />
        <h3 class="px-4">
          {{ voucherContent.title }}
        </h3>
      </div>
      <v-img
        v-else
        contain
        max-height="60px"
        :src="voucherContent.logo"
      />
    </v-card-title>

    <v-card-subtitle class="pt-2 mx-2 subtitle">
      {{ voucherContent.subTitle }}
    </v-card-subtitle>

    <v-card-text class="px-6">
      <div class="my-2 text-overflow">
        <p
          v-for="(text, index) in voucherContent.text"
          :key="index"
        >
          {{ text }}
        </p>
        <p
          v-for="(entry, index) in voucherContent.textWithLink"
          :key="`link-${index}`"
        >
          {{ entry.text }}
          <a
            :href="entry.link"
            target="_blank"
            rel="noopener noreferrer"
          >
            {{ entry.link }}
          </a>
        </p>
        <p v-if="voucherContent.supportMail">
          {{ $t('partner.voucher.get-support') }}:
          <a :href="`mailto:${voucherContent.supportMail}`">
            {{ voucherContent.supportMail }}
          </a>
        </p>
      </div>

      <v-row
        class="my-6"
        align="center"
        justify="space-around"
      >
        <v-text-field
          v-if="voucher.code"
          class="mx-2 mb-4 shrink voucher-code"
          :label="$t('partner.voucher.label')"
          outlined
          readonly
          hide-details
          :value="voucher.code"
        />
        <v-text-field
          v-else-if="voucherContent.code"
          class="mx-2 mb-4 shrink voucher-code"
          :label="$t('partner.voucher.label')"
          outlined
          readonly
          hide-details
          :value="voucherContent.code"
        />

        <v-btn
          x-large
          class="mx-2 mb-4 primary"
          :href="voucherContent.registrationLink"
          target="_blank"
          rel="noopener noreferrer"
        >
          {{ voucherContent.registrationLinkText || $t('buttons.partner.voucher.cta') }}
          <v-icon
            class="ml-2"
            size="14"
          >
            mdi-open-in-new
          </v-icon>
        </v-btn>
      </v-row>
      <span v-if="voucher.expiresAt">
        {{ $t('partner.voucher.valid-until') }}: {{ formatDate(voucher.expiresAt) }}
      </span>
    </v-card-text>
  </v-card>
</template>

<script>
import brandingMixin from '@/mixins/branding'
import { PARTNER_TYPES } from './helper/partner'

export default {
  mixins: [brandingMixin],
  props: {
    voucher: {
      type: Object,
      default: null
    }
  },
  computed: {
    voucherContent () {
      const { locale } = this.$i18n
      return PARTNER_TYPES[this.platform][this.voucher.type][locale]
    }
  }
}
</script>

<style scoped>
.subtitle {
  font-weight: bold;
}
.voucher-code :deep(input) {
  text-align: center;
}
.text-overflow {
  max-height: 200px;
  overflow-y: scroll
}
.title {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}
</style>
